@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Urbanist", sans-serif;
}

.container {
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

section {
  padding: 50px 0;
}
