@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-neutral-800;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}
